import {
  Branch,
  Doctor,
  DoctorUI,
  Patient,
  Service,
  Slot,
  Specialty,
  WidgetInfo,
  WidgetServiceRequestType,
} from 'src/shared/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface AppointmentState {
  specialty?: Specialty;
  doctor?: DoctorUI | Doctor;
  widgetInfo?: WidgetInfo;
  doctorId?: string;
  slot?: Slot;
  medOrganization?: Branch;
  service?: Service;
  appointmentId?: string;
  patient?: Patient;
  serviceRequestType?: WidgetServiceRequestType;
  doctorsActiveTab?: any;
}

const initialState: AppointmentState = {};

export const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    setSpecialty: (state, action: PayloadAction<Specialty | undefined>) => {
      state.specialty = action.payload;
    },
    setDoctor: (state, action: PayloadAction<DoctorUI | Doctor | undefined>) => {
      state.doctor = action.payload;
    },
    setDoctorId: (state, action: PayloadAction<string>) => {
      state.doctorId = action.payload;
    },
    setSlot: (state, action: PayloadAction<Slot>) => {
      state.slot = action.payload;
    },
    setMedOrganization: (state, action: PayloadAction<Branch>) => {
      state.medOrganization = action.payload;
    },

    setService: (state, action: PayloadAction<Service>) => {
      state.service = action.payload;
    },

    setAppointmentId: (state, action: PayloadAction<string>) => {
      state.appointmentId = action.payload;
    },

    setPatient: (state, action: PayloadAction<Patient>) => {
      state.patient = action.payload;
    },

    setServiceRequestType: (state, action: PayloadAction<WidgetServiceRequestType>) => {
      state.serviceRequestType = action.payload;
    },
    
    setDoctorActiveTab: (state, action: PayloadAction<{doctorId: any, tab: any}>) => {
      if (!state.doctorsActiveTab) {
        state.doctorsActiveTab = {};
      }
      state.doctorsActiveTab[action.payload.doctorId] = action.payload.tab;
    },

    resetAppointmentData: () => {
      return {} as AppointmentState;
    },
  },
});

export const {
  setSpecialty,
  setDoctor,
  setDoctorId,
  setSlot,
  setMedOrganization,
  setService,
  setAppointmentId,
  setPatient,
  setServiceRequestType,
  resetAppointmentData,
  setDoctorActiveTab
} = appointmentSlice.actions;

export const appointmentReducer = appointmentSlice.reducer;
